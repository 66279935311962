<template>
  <main id="main" class="reminder">
    <div class="wrap1160">
      <div class="remind-msg-comp">
        <p v-html="$t('SYSTEM_ERROR_MESSAGE')"></p>
        <div class="remind-comp-btn">
          <a class="btnBsc-Black" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t('REISSUE_PASSWORD_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL')}}<img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印"></a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods : {
    forceReload() {
      this.$router.push('/')
    }
  }
}
</script>
<style scoped lang="scss">
  @media only screen and (max-width: 767px) {
    #main.reminder [class^="remind-msg"] p {
      text-align: center !important;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
